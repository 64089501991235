.AlertSmall {
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  padding: 10px 16px;
  border-radius: var(--br-button);
}
.AlertSmall a {
  color: white;
}

.AlertSmall.warn {
  background-color: var(--c-yellow);
}
.AlertSmall.warn a {
  background-color: var(--c-yellow);
  color: var(--c-default-text);
}
.AlertSmall.info {
  background-color: var(--c-blue-bg);
  border: 1px solid var(--c-blue-border);
  color: var(--c-default-text);
  font-weight: 500;
}
.AlertSmall.success {
  background-color: var(--c-green-bg);
  border: 1px solid var(--c-green-border);
  color: var(--c-default-text);
  font-weight: 500;
}
.AlertSmall.error {
  background-color: var(--c-red-bg);
  border: 1px solid var(--c-red-border);
  color: var(--c-default-text);
  font-weight: 500;
}
.AlertSmall .ctn-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.AlertSmall .ctn-icon .icon {
  color: var(--c-blue-text);
}
.AlertSmall.warn .icon {
  color: var(--c-yellow);
}
.AlertSmall.error .icon {
  color: var(--c-red);
}
.AlertSmall.success .icon {
  color: var(--c-green);
}
