@font-face {
  font-family: "Title";
  src: url("../font/Evolventa-Bold.ttf");
}
@font-face {
  font-family: "Text";
  src: url("../font/Loukaaa-font-text.ttf");
}

:root {
  /* Font */
  --font-family: "Text", sans-serif;
  --ff-default: "Text", sans-serif;
  --ff-title: "Title", sans-serif;

  /* Color */
  /* #6E40FF */
  --c-default: #3b2fec;
  --c-default-hover: #3328c6;
  --c-default-disabled: #3228c6dd;

  --c-text: #000000;
  --c-text-hover: #838490;

  --c-default-bg: #ffffff;
  --c-secondy-bg: #111;

  --c-blue-text: #3b2fec;
  --c-blue-text-hover: #3328c6;
  --c-blue-border: rgba(35, 53, 114, 0.09);
  --c-blue-bg: #e0ebff;

  --c-gray-text: #424242;
  --c-gray-text-hover: #7c7c7c;
  --c-gray-border: #363636;
  --c-gray-bg: #eee;

  --c-red: #c32626;
  --c-red-hover: #c80923;
  --c-red-disabled: #c11f35;
  --c-red-bg: #feedee;
  --c-red-border: rgba(114, 35, 35, 0.09);

  --c-green: #38a169;
  --c-green-hover: #2f8e5e;
  --c-green-disabled: #2f8e5e;
  --c-green-bg: #e6f4f1;
  --c-green-border: rgb(35, 114, 72, 0.09);

  --c-yellow: #fee3ac;
  --c-yellow-border: rgb(238, 215, 169);

  --c-text-background: white;

  /* Bg */
  --bg-red: #fcefec;
  --bg-blue: #e8eefa;

  /* Border */
  --b-default: 1px solid #e8e8ea;
  --b-red: 1px solid #ecd1c9;
  --b-blue: 1px solid #b6cdec;
  --b-gray: 1px solid #d6d6d6;

  /* Border Radius */
  --br-default: 12px;
  --br-button: 30px;
  --br-little: 6px;

  /* Box-shadow */
  --box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --bs-default: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --box-shadow-ctn: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --bs-ctn: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* transition */
  --transition: all 0.2s;
  --tr-default: all 0.2s;
}

/* var css to class */
.c-default {
  color: var(--c-default);
}
.c-default-hover {
  color: var(--c-default-hover);
}
.c-default-disabled {
  color: var(--c-default-disabled);
}

.c-white {
  color: white !important;
}

/* --------------- */

*::before,
::after {
  font-family: var(--font-family);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--c-text);
}

/* G-Y8V1YX6YB8 */

body {
  font-family: var(--ff-default);
  margin: 0;
  background-color: var(--c-default-bg);
  color: var(--c-text);
}
.inner {
  max-width: 1000px;
  width: calc(100% - 40px);
  margin: auto;
  padding: 20px;
}

.text-xxs {
  font-size: 14px;
  font-weight: 500;
  color: #939394;
  margin: 5px 0 0 0;
}
.text-xs {
  font-size: 16px;
  font-weight: 500;
  color: #939394;
  margin: 5px 0 0 0;
}
.text-sm {
  font-size: 18px;
  font-weight: 500;
  color: #939394;
  margin: 10px 0 0 0;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
  font-weight: 500;
}
.text-xl {
  font-size: 1.25rem;
  font-weight: 500;
}
.text-2xl {
  font-size: 1.5rem;
  font-weight: 500;
}
.text-3xl {
  font-size: 1.875rem;
  font-weight: 700;
}
.text-4xl {
  font-size: 2.25rem;

  font-weight: 700;
}
.text-5xl {
  font-size: 3rem;

  font-weight: 700;
}
.text-6xl {
  font-size: 4rem;
  line-height: 4.2rem;
  font-weight: 600;
}

/* -------------------------------------------------------------- */

/* Settings li | a */
li {
  list-style: none;
}

a {
  color: var(--c-default-text);
  font-weight: 500;
  outline: none !important;
  text-decoration: none;
}

/* other */
input[type="submit"] {
  outline: none;
  border: none;
}

input[type="radio"] {
  display: none;
}

.button--Under {
  color: var(--c_gray);
  padding: var(--padding);
  background: none;
  cursor: pointer;
}

.button--Option {
  color: var(--c_primary);
  padding: var(--padding);
  background: none;
  cursor: pointer;
  font-size: 18px;
}

/* --------------------------- Title ---------------------------- */
/* -------------------------------------------------------------- */

h1,
.h1 {
  font-family: var(--ff-title);
  font-size: clamp(2rem, 1.25rem + 6vw, 5rem);
  font-weight: 900;
  margin: 0;
}
h2,
.h2 {
  font-family: var(--ff-title);
  font-size: clamp(1.625rem, 0.9375rem + 5.5vw, 4.375rem);
  font-weight: 500;
  margin: 0;
}
h3,
.h3 {
  font-family: var(--ff-title);
  font-size: clamp(1.375rem, 0.8594rem + 4.125vw, 3.4375rem);
  font-weight: 500;
  margin: 0;
}
h4,
.h4 {
  font-family: var(--ff-title);
  font-size: clamp(1.3rem, 1.0938rem + 1.25vw, 3rem);
  font-weight: 600;
  margin: 0;
}
h5,
.h5 {
  font-family: var(--ff-title);
  font-size: clamp(1.0625rem, 1.0156rem + 0.375vw, 1.25rem);
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* -------------------------------------------------------------- */
/* --------------------------- Items ---------------------------- */

p {
  font-size: clamp(1rem, 0.9063rem + 0.75vw, 1.375rem);
  line-height: 150%;
}

/* ------------------------- Components ------------------------- */
/* -------------------------------------------------------------- */

.button {
  font-family: var(--ff-default);
  display: inline-block;
  padding: 12px 25px;
  border-radius: var(--border-radius-button);
  background-color: var(--c-default);
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}
.button:hover {
  background-color: var(--c-default-hover);
}
.button:focus {
  outline: 3px solid var(--c-default-hover);
  outline-offset: 3px;
}

/* -------------------------------------------------------------- */
/* ------------------------- CSS CLASS -------------------------- */

.__inner {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

/* -> BG */
.bg-red {
  background-color: var(--bg-red);
}
.bg-blue {
  background-color: var(--bg-blue);
}
.bg-black {
  background-color: black;
}

/* -> MARGIN-TOP */
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}

/* -> MARGIN-BOTTOM */
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}

/* -> PADDING */
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.p-40 {
  padding: 40px;
}
.p-50 {
  padding: 50px;
}
