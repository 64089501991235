.InputComponents {
  width: 100%;
}
.InputComponents .text-sm {
  font-weight: 600;
  color: var(--c-gray-text);
}
.InputComponents-container,
.PhoneInput {
  cursor: text;
  display: flex;
  align-items: center;
  border-radius: var(--br-button);
  border: var(--b-default);
  padding: 12px 20px;
}
.InputComponents-container.active,
.PhoneInput--focus {
  outline: 2px solid var(--c-default);
  outline-offset: 2px;
}
.InputComponents-container .icon {
  font-size: 18px;
  color: var(--c-gray-bg);
  margin-right: 10px;
}
.InputComponents-container.active .icon {
  color: var(--c-default);
}
.InputComponents-container input,
.InputComponents-container textarea {
  font-family: var(--font-family);
  width: 100%;
  outline: none;
  border: none;
  font-weight: 500;
  background: none;
}
