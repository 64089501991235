.Login {
  display: flex;
  align-items: center;
  gap: 30px;
  height: calc(100vh - 80px);
  width: calc(100% - 80px);
  max-width: calc(1300px - 80px);
  margin: auto;
  padding: 40px;
}

.Login > div {
  flex: 1;
}

.Login .left {
  height: 100%;
  min-height: 100%;
  background-color: var(--c-default);
  border-radius: var(--br-default);
}
.Login .left img {
  width: 100%;
  min-height: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--br-default);
}

.Login .right a {
  color: var(--c-default);
}
.Login .right a:hover {
  color: var(--c-default-hover);
}

.Login .right .header {
  border-bottom: var(--b-default);
  padding: 20px 0;
}
.Login .right .header h3,
.Login .right .header p {
  margin: 0;
}

.Login .right .form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: var(--b-default);
  padding: 20px 0;
}
.Login .right .form .two-input {
  display: flex;
  align-items: center;
  gap: 15px;
}
.Login .right .form .ctn-checkbox {
  display: flex;
  align-items: center;
  gap: 15px;
}
.Login .right .form .ctn-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  color: var(--c-default);
}

.checkbox-wrapper * {
  box-sizing: border-box;
}
.checkbox-wrapper .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
}
.checkbox-wrapper .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper .cbx:hover {
  background: rgba(47, 0, 255, 0.06);
}
.checkbox-wrapper .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(40, 0, 75, 0.05);
}
.checkbox-wrapper .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper .cbx:hover span:first-child {
  border-color: var(--c-default);
}
.checkbox-wrapper .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper .inp-cbx:checked + .cbx span:first-child {
  background: var(--c-default);
  border-color: var(--c-default);
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .checkbox-wrapper .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

.Login .right .footer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 0;
}
.Login .right .footer span {
  text-align: center;
  margin: 10px 0;
}

@media screen and (max-width: 900px) {
  .Login .left {
    display: none;
  }
  .Login .right {
    max-width: 600px;
    margin: auto;
  }
}
